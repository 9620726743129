import * as React from 'react'
import { useQuery } from '@apollo/client'
import { useFeature } from '@toasttab/ec-features'
import {
  CustomerContextCustomer,
  CustomerContextProvider
} from '@local/contexts'
import {
  UserContextProvider,
  UserContextUser
} from '@local/contexts/src/user-context'
import { ApplicationFeaturesQuery } from './api-documents'

type AppContextProps = {
  user: UserContextUser
}

/** Make available all the shared app data such as the customer data **/
export const AppContext: React.FunctionComponent<
  React.PropsWithChildren<AppContextProps>
> = ({ children, user }) => {
  const [customerContextValue, setCustomerContextValue] = React.useState<
    CustomerContextCustomer | undefined
  >(() => undefined)

  const [userContextValue] = React.useState<UserContextUser>(() => user)
  const { data, loading } = useQuery(ApplicationFeaturesQuery)
  const hasVestwellFeeatureFlag = useFeature(
    'ec-pcard-show-vestwell-tile',
    false
  )
  const hasSimplyInsuredFeatureFlag = useFeature(
    'ec-pcard-simply-insured',
    false
  )
  const hasLegacyBenefitsFeatureFlag = useFeature(
    'ec-pcard-legacy-benefits',
    false
  )

  React.useEffect(() => {
    if (!loading) {
      setCustomerContextValue({
        hasBenefitsBrokerageAdministration:
          !!data?.customer?.enabledFeatures.includes(
            'BENEFITS_BROKERAGE_ADMINISTRATION'
          ),
        hasLegacyBenefitsModule: hasLegacyBenefitsFeatureFlag,
        hasAcaModule: hasLegacyBenefitsFeatureFlag,
        hasVestwellRetirementPlanOption: hasVestwellFeeatureFlag,
        hasSimplyInsuredBenefitsOption: hasSimplyInsuredFeatureFlag
      })
    }
  }, [
    hasLegacyBenefitsFeatureFlag,
    hasSimplyInsuredFeatureFlag,
    hasVestwellFeeatureFlag,
    data,
    loading
  ])

  return customerContextValue ? (
    <CustomerContextProvider value={customerContextValue}>
      <UserContextProvider value={userContextValue}>
        {children}
      </UserContextProvider>
    </CustomerContextProvider>
  ) : null
}
