import * as React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import cx from 'classnames'
import {
  FocusBody,
  FocusClose,
  FocusHeader,
  FocusView,
  HeadingGroup,
  Title
} from '@toasttab/buffet-pui-config-templates'
import { getCurrentEnvironment } from '@toasttab/current-environment'
import { t } from '@local/translations'
import { useTracking } from '@local/track'
import { CloseConfirmation } from './CloseConfirmation'
import { InsuranceFlow } from '../../third-part-data-sharing-consent-modal'

/**
 * Opens the SimplyInsured experience via an iFrame inside of a buffet FocusView component
 */
export const SimplyInsuredFocusView: React.FunctionComponent = () => {
  const [isCloseConfirmationOpen, setIsCloseConfirmationOpen] =
    React.useState<boolean>(false)
  const { track } = useTracking()

  const onCloseRequest = () => {
    track('employee-benefits-spa.simply-insured.request-close')
    setIsCloseConfirmationOpen(true)
  }
  const onCancelCloseRequest = () => setIsCloseConfirmationOpen(false)

  // this ensures we don't get two scroll bars, one in the iframe and one in the focus body
  const heightClass = 'h-[calc(100vh-156px)] sm:h-[calc(100vh-174px)]'

  const insuranceFlow = useLocation().state?.insuranceFlow
  if (insuranceFlow !== 'quote' && insuranceFlow !== 'broker-of-record') {
    return <Navigate to='/whitecaptest/benefits' replace />
  } else {
    const simplyInsuredUrl = getSimplyInsuredUrl(insuranceFlow)
    return (
      <>
        {isCloseConfirmationOpen && (
          <CloseConfirmation onCancel={onCancelCloseRequest} />
        )}
        <FocusView>
          <FocusHeader>
            <HeadingGroup subTitle={t('simply-insured.subtitle')}>
              <Title>{t('simply-insured.title')}</Title>
            </HeadingGroup>
            <FocusClose onClick={onCloseRequest} />
          </FocusHeader>
          <FocusBody className={cx(heightClass, 'pb-4 sm:pb-6')}>
            <iframe
              className={cx(heightClass, 'w-full pb-4 sm:pb-6')}
              title='Simply Insured iframe'
              src={simplyInsuredUrl.href}
            />
          </FocusBody>
        </FocusView>
      </>
    )
  }
}

const getSimplyInsuredUrl = (insuranceFlow: InsuranceFlow): URL => {
  const environment = getCurrentEnvironment()

  const simplyInsuredUrl = new URL(
    environment === 'prod'
      ? 'https://www.simplyinsured.com/omniauth/toast'
      : 'https://staging.simplyinsured.com/omniauth/toast'
  )

  if (insuranceFlow === 'broker-of-record') {
    simplyInsuredUrl.searchParams.append('flow_type', 'broker_of_record')
  }

  // todo (jira story not created yet) add customer uuid and fein uuid query params when clicking on `view and manage` button
  return simplyInsuredUrl
}
