import * as React from 'react'
import cx from 'classnames'
import { useLayoutContext } from '../../common/LayoutProvider'
import { HEADER_SCROLL_REF_HEIGHT, FOCUS_VIEW_BUFFER } from '../../common'

interface FocusBodyProps {
  testId?: string | number
  className?: string
}

export const FocusBody: React.FC<React.PropsWithChildren<FocusBodyProps>> = ({
  testId = 'focus-view-body',
  className = '',
  children
}) => {
  const { disableMaxWidth, headerScrollRef, isHeaderCollapsed } =
    useLayoutContext()

  return (
    <div
      className={cx('pb-20 sm:p-16 sm:pt-0 sm:pb-40', className)}
      data-testid={testId}
    >
      <div
        className='mx-auto'
        style={{ maxWidth: disableMaxWidth ? 'none' : '1040px' }}
      >
        <div
          ref={headerScrollRef}
          className='absolute left-0 top-0 w-1'
          style={{
            height: `${HEADER_SCROLL_REF_HEIGHT}px`,
            marginTop: isHeaderCollapsed ? `-${FOCUS_VIEW_BUFFER}px` : 0
          }}
        />
        {children}
      </div>
    </div>
  )
}
