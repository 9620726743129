import * as React from 'react'
import { ApolloError, useMutation } from '@apollo/client'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  PayrollConsentModal,
  PayrollConsentModalProps
} from '@toasttab/buffet-pui-payroll-consent-modal'
import { t, Trans } from '@local/translations'
import { trackConsent, useTracking } from '@local/track'
import { ErrorModal } from '@local/error-modal'
import { DataSharingConsentMutation } from './api-documents'
import { DataSharingConsentMutationMutation } from '@local/grouter'

const CONSENT_VERSION = 1.0

export type InsuranceFlow = 'broker-of-record' | 'quote'

export type ConsentModalProps = {
  insuranceFlow: InsuranceFlow
} & Pick<PayrollConsentModalProps, 'onRequestClose'>

/**
 * Third party data sharing consent modal used to show the copy approved by the legal team for sharing data with SimplyInsured.
 * Should be shown every time the user wants to change the insurance broker of record or get a quote
 **/
export const ConsentModal: React.FunctionComponent<ConsentModalProps> = ({
  insuranceFlow,
  onRequestClose
}) => {
  const [isError, setIsError] = React.useState<boolean>(false)

  const [isChecked, setIsChecked] = React.useState<boolean>(false)
  const toggleChecked = () => setIsChecked(!isChecked)

  const { onConfirm } = useSaveConsent(insuranceFlow, onRequestClose, () =>
    setIsError(true)
  )

  return !isError ? (
    <PayrollConsentModal
      {...trackConsent('modal')}
      header={t('third-party-consent.header')}
      primaryDisclaimer={
        <Trans
          i18nKey='third-party-consent.disclosure.primary'
          components={[
            <a
              {...trackConsent('privacy-statement')}
              key='privacy-statement-link'
              className='inline-link cursor-pointer'
              href='https://pos.toasttab.com/privacy'
              target='_blank'
              rel='noopener noreferrer'
            >
              {t('third-party-consent.disclosure.primary')}
            </a>
          ]}
        />
      }
      acknowledgementCheckbox={{
        checked: isChecked,
        label: t('third-party-consent.acknowledgement'),
        onChange: toggleChecked
      }}
      secondaryDisclaimer={
        <Trans
          i18nKey='third-party-consent.disclosure.secondary'
          components={[
            <a
              {...trackConsent('simply-insured-insurance-licenses')}
              key='simply-insured-insurance-license-link'
              className='inline-link cursor-pointer'
              href='https://get.simplyinsured.com/insurance-licenses'
              target='_blank'
              rel='noopener noreferrer'
            >
              {t('third-party-consent.disclosure.secondary')}
            </a>,
            <a
              {...trackConsent('toast-insurance-licenses')}
              key='toast-insurance-license-link'
              className='inline-link cursor-pointer'
              href='https://pos.toasttab.com/insurance-licenses'
              target='_blank'
              rel='noopener noreferrer'
            >
              {t('third-party-consent.disclosure.secondary')}
            </a>
          ]}
        />
      }
      cancelButton={{
        label: t('third-party-consent.button.cancel'),
        onClick: onRequestClose
      }}
      confirmButton={{
        disabled: !isChecked,
        label: t('third-party-consent.button.consent'),
        onClick: onConfirm
      }}
      isOpen={true}
      onRequestClose={onRequestClose}
    />
  ) : (
    <ErrorModal
      isOpen={true}
      onRequestClose={onRequestClose}
      retryOnClick={onConfirm}
    />
  )
}

const useSaveConsent = (
  insuranceFlow: InsuranceFlow,
  onRequestClose: VoidFunction | undefined,
  onError: VoidFunction
) => {
  const [saveDataSharingConsent] = useMutation(DataSharingConsentMutation)
  const navigate = useNavigate()
  const location = useLocation()

  const onMutationCompleted = (data: DataSharingConsentMutationMutation) => {
    const resultVersion = data.updateBenefitsConsent?.version
    if (resultVersion !== CONSENT_VERSION) {
      onMutationError({
        message: `returned success, but response version '${resultVersion}' does not match expected version '1'`
      })
    } else {
      onRequestClose && onRequestClose()
      navigate(
        location.pathname.endsWith('/benefits')
          ? 'group-health/simply-insured'
          : 'simply-insured',
        { state: { insuranceFlow: insuranceFlow } }
      )
    }
  }

  const onMutationError = (error: Pick<ApolloError, 'message'>) => {
    console.error('updateBenefitsConsent mutation', error.message)
    onError()
  }

  const { track } = useTracking()

  return {
    onConfirm: () => {
      track('employee-benefits-spa.consent.confirm')
      saveDataSharingConsent({
        variables: {
          version: CONSENT_VERSION
        },
        onCompleted: onMutationCompleted,
        onError: onMutationError
      })
    }
  }
}
