import { graphql } from '@local/grouter'

export const ApplicationFeaturesQuery = graphql(`
  query ApplicationFeaturesQuery {
    customer {
      __typename
      uuid
      enabledFeatures
    }
  }
`)
