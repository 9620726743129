/* eslint-disable */
import * as types from './graphql'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
  '\n  query ApplicationFeaturesQuery {\n    customer {\n      __typename\n      uuid\n      enabledFeatures\n    }\n  }\n':
    types.ApplicationFeaturesQueryDocument,
  '\n  query BenefitsQuery {\n    customer {\n      feins {\n        ...BenefitsQueryFein\n      }\n      id\n      uuid\n    }\n  }\n':
    types.BenefitsQueryDocument,
  '\n  fragment BenefitsQueryFein on PayrollFein {\n    benefits {\n      benefitType\n    }\n    fein\n    id\n    name\n  }\n':
    types.BenefitsQueryFeinFragmentDoc,
  '\n  mutation DataSharingConsentMutation($version: Float!) {\n    updateBenefitsConsent(version: $version) {\n      version\n    }\n  }\n':
    types.DataSharingConsentMutationDocument
}

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ApplicationFeaturesQuery {\n    customer {\n      __typename\n      uuid\n      enabledFeatures\n    }\n  }\n'
): typeof documents['\n  query ApplicationFeaturesQuery {\n    customer {\n      __typename\n      uuid\n      enabledFeatures\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query BenefitsQuery {\n    customer {\n      feins {\n        ...BenefitsQueryFein\n      }\n      id\n      uuid\n    }\n  }\n'
): typeof documents['\n  query BenefitsQuery {\n    customer {\n      feins {\n        ...BenefitsQueryFein\n      }\n      id\n      uuid\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment BenefitsQueryFein on PayrollFein {\n    benefits {\n      benefitType\n    }\n    fein\n    id\n    name\n  }\n'
): typeof documents['\n  fragment BenefitsQueryFein on PayrollFein {\n    benefits {\n      benefitType\n    }\n    fein\n    id\n    name\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DataSharingConsentMutation($version: Float!) {\n    updateBenefitsConsent(version: $version) {\n      version\n    }\n  }\n'
): typeof documents['\n  mutation DataSharingConsentMutation($version: Float!) {\n    updateBenefitsConsent(version: $version) {\n      version\n    }\n  }\n']

export function graphql(source: string) {
  return (documents as any)[source] ?? {}
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never
