import * as React from 'react'
import cx from 'classnames'
import { useLayoutContext } from '../../common/LayoutProvider'

interface FocusHeaderProps {
  testId?: string | number
  /**
   * @deprecated This property can be removed. It is now queried via introspection based on the presence
   * of the PageTargetNavLayout or PageTargetNavLoading components.
   *
   * set to true when you have added PageTargetNavLayout component in the PageBody
   */
  isUsingPageTargetNav?: boolean
  className?: string
}

export const FocusHeader = ({
  testId = 'focus-view-header',
  className = '',
  children
}: React.PropsWithChildren<FocusHeaderProps>) => {
  const { disableMaxWidth, headerRef, isHeaderCollapsed, isUsingLeftNav } =
    useLayoutContext()

  return (
    <header
      ref={headerRef}
      className={cx(
        'top-0 bg-page z-10', // elevation: sticky headers use z-10 according to our guidelines
        'sm:transition-all duration-300',
        'sm:p-16 sm:pt-8 sm:pb-0',
        !isHeaderCollapsed && 'lg:p-16 lg:pt-16 lg:pb-0',
        isUsingLeftNav ? 'lg:sticky' : 'sticky',
        className
      )}
      data-testid={testId}
    >
      <div
        className='mx-auto'
        style={{ maxWidth: disableMaxWidth ? 'none' : '1040px' }}
      >
        <div
          className={cx(
            'border-gray-50 relative px-4 pt-6 pb-4 sm:p-0',
            'sm:pb-4 sm:mb-4',
            !isHeaderCollapsed && 'lg:pb-6 lg:mb-6',
            isUsingLeftNav ? 'sm:border-b' : 'border-b mb-4'
          )}
        >
          {/* FocusClose is usually present, so we'll add padding to account for it */}
          <div className='relative flex flex-row justify-between pr-12 sm:pr-0'>
            {children}
          </div>
        </div>
      </div>
    </header>
  )
}
