import * as React from 'react'
import cx from 'classnames'
import { IconButton, IconButtonProps } from '@toasttab/buffet-pui-buttons'
import { CloseIcon } from '@toasttab/buffet-pui-icons'

type FocusCloseProps<
  TElementType extends React.ElementType = typeof defaultElement
> = Omit<IconButtonProps<TElementType>, 'icon' | 'iconColor'> & {
  as?: TElementType
  /**
   * @deprecated Please use `label` instead.
   */
  text?: string
  /**
   * An accessible label assigned to the button, when the icon cannot be as
   * descriptive.
   */
  label?: string
  /**
   * @deprecated FocusCloseButton is now deprecated, and FocusClose renders its
   * own action based on the `as` prop, to use a button, anchor or Link.
   */
  children?: React.ReactNode
}

const defaultElement = 'button'

export function FocusClose<
  TElementType extends React.ElementType = typeof defaultElement
>({
  className,
  testId = 'focus-close',
  // eslint-disable-next-line
  text = 'Close',
  label = 'Close',
  // eslint-disable-next-line
  children,
  ...restProps
}: FocusCloseProps<TElementType>): JSX.Element {
  return (
    <div
      className={cx(
        'absolute top-0 right-0 -mt-3 sm:left-0 sm:bottom-0 sm:-ml-14 sm:mt-0 sm:mr-0 sm:top-auto sm:right-auto',
        className
      )}
      data-testid={testId}
    >
      {children || (
        <IconButton
          aria-label={label}
          as={defaultElement}
          {...(restProps as IconButtonProps<TElementType>)}
          className='flex'
          icon={<CloseIcon accessibility='decorative' />}
          iconColor='secondary'
        />
      )}
    </div>
  )
}
