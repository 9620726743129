import * as React from 'react'
import cx from 'classnames'
import {
  FlushBreakpoint,
  LayoutProviderInternal,
  useLayoutContext,
  useLayoutContextDeprecated
} from '../../common/LayoutProvider'
import {
  useFloating,
  PortalManagement,
  Overlay,
  useMergeRefs
} from '@toasttab/buffet-pui-floating-ui-base'

export interface FocusViewProps {
  testId?: string | number
  disableMaxWidth?: boolean
  scrollToTop?: boolean
  className?: string
  /**
   * By default a FocusView will auto-focus to the first focusable element in the FocusView.
   * This is particularly useful when you have input fields in your FocusView. But there
   * could be times when you need to disable this behavior and if you find yourself in such
   * a situation, set `disableInitialFocus` to true. Note that you can use the `onRequestOpen`
   * prop to manually place the focus on an element of your choice if you find yourself in this
   * situation.
   */
  disableInitialFocus?: boolean
}

export const FocusView: React.FC<React.PropsWithChildren<FocusViewProps>> = ({
  testId = 'focus-view',
  disableMaxWidth,
  scrollToTop,
  className = '',
  disableInitialFocus,
  children
}) => {
  const {
    disableMaxWidth: disableMaxWidthDeprecated,
    scrollToTop: scrollToTopDeprecated
  } = useLayoutContextDeprecated()

  return (
    <LayoutProviderInternal
      disableMaxWidth={disableMaxWidth || disableMaxWidthDeprecated}
      scrollToTop={scrollToTop || scrollToTopDeprecated}
      flushBreakpoint={FlushBreakpoint.SM}
      isFocusView
    >
      <FocusViewInternal
        testId={testId}
        className={className}
        disableInitialFocus={disableInitialFocus}
      >
        {children}
      </FocusViewInternal>
    </LayoutProviderInternal>
  )
}

const FocusViewInternal = ({
  testId,
  className,
  disableInitialFocus,
  children
}: FocusViewProps & { children: React.ReactNode }) => {
  const { sectionRef } = useLayoutContext()
  const { context, refs } = useFloating({ open: true })
  const ref = useMergeRefs([sectionRef, refs.setFloating])
  const zIndexClassName = 'z-30' // elevation: z-30 for full screen layouts according to our guidelines

  return (
    <PortalManagement
      context={context}
      isModal={true}
      zIndexClassName={zIndexClassName}
      disableInitialFocus={disableInitialFocus}
    >
      <Overlay
        testId={`overlay-${testId}`}
        lockScroll
        id='modal-overlay'
        className={zIndexClassName}
      >
        <section
          data-testid={testId}
          ref={ref}
          className={cx(
            'fixed top-0 left-0 right-0 bottom-0 overflow-y-auto bg-page',
            className
          )}
        >
          {children}
        </section>
      </Overlay>
    </PortalManagement>
  )
}
