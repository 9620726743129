const testRegex = /^test$/
const envRegex =
  /(?:.*\.)*(preprod|dev|sandbox|pre|staging|test-app|test-login|local-app|local-login)(?:\.|-).*\.com/
const prodEnvRegex = /prod/
const prodRegex = /(toasttab\.com|getsling\.com|getsling\.com)/
const devRegex =
  /(localhost|\b(?:(?:25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d\d?)(?::\d{0,4})?\b)/
const devPayrollRegex = /payroll.eng.toastteam.com/
const devSlingRegex = /local-app\.getsling\.com|local-login\.getsling\.com/
const devXtrachefRegex = /bpo\.xtrachef\.com/

export enum ToastEnvironment {
  PROD = 'prod',
  SANDBOX = 'sandbox',
  PREPROD = 'preprod',
  DEV = 'dev',
  TEST = 'test'
}

export type ToastEnvironmentList = keyof typeof ToastEnvironment

const ENV_RENAME = new Map([
  ['pre', 'preprod'],
  ['staging', 'preprod'],
  ['test-app', 'preprod'],
  ['test-login', 'preprod']
])

export function getEnvironment(url: string = ''): ToastEnvironment {
  if (!url || typeof url !== 'string') {
    throw new Error(
      `Provided URL is not valid to get current environment name ${url}`
    )
  }
  const isTestMatch = testRegex.test(url)
  const isProdMatch = prodRegex.test(url) || prodEnvRegex.test(url)
  const devMatch =
    devRegex.test(url) ||
    devPayrollRegex.test(url) ||
    devSlingRegex.test(url) ||
    devXtrachefRegex.test(url)
  const otherEnvMatch = url.match(envRegex)
  const otherEnvBase = otherEnvMatch?.[1] ?? ''
  const otherEnv = ENV_RENAME.get(otherEnvBase) ?? otherEnvBase

  if (isTestMatch) return ToastEnvironment.TEST
  else if (isProdMatch && !otherEnvMatch) return ToastEnvironment.PROD
  else if (devMatch) return ToastEnvironment.DEV
  return ToastEnvironment[otherEnv.toUpperCase() as ToastEnvironmentList]
}
