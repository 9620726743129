import * as React from 'react'

export type CustomerContextCustomer = {
  /** if the customer has the benefits brokerage administration entitlement from toastweb **/
  hasBenefitsBrokerageAdministration: boolean
  /** if the customer has the legacy benefits module feature flag **/
  hasLegacyBenefitsModule: boolean
  /** if the customer has the affordable care act module feature flag **/
  hasAcaModule: boolean
  /** if the customer has the SimplyInsured feature flag **/
  hasSimplyInsuredBenefitsOption: boolean
  /** if the customer has the vestwell retirement plan feature flag **/
  hasVestwellRetirementPlanOption: boolean
}

export type CustomerContextValue = CustomerContextCustomer | null

const CustomerContext: React.Context<CustomerContextValue> =
  React.createContext<CustomerContextValue>(null)

export const CustomerContextProvider: React.FunctionComponent<{
  value: CustomerContextValue
  children: React.ReactNode
}> = ({ value, children }) => {
  return (
    <CustomerContext.Provider value={value}>
      {children}
    </CustomerContext.Provider>
  )
}

export const useCustomer = () => {
  const customerContext = React.useContext(CustomerContext)
  if (customerContext === null) {
    throw new Error('Customer context has not been initialized properly. ')
  }
  return customerContext
}
